<template>
  <div class="register full-layout">
    <div class="register__container">
      <div class="register__image-block">
        <img alt="" class="register__image" src="../../assets/logo.png" />
        Регистрация в системе
      </div>

      <div class="register__inputs-block">
        <zem-input v-model="user.name" :max-length="255" placeholder="Введите имя" />
        <zem-input v-model="user.email" :max-length="255" placeholder="Введите email" />
        <zem-input v-model="user.password" :max-length="255" placeholder="Введите пароль" type="password" />
        <div class="register__inputs-block__buttons">
          <zem-button
            :disabled="user.name.length === 0 || user.email.length === 0 || user.password.length === 0"
            @click="handleRegister"
            >Зарегистрироваться
          </zem-button>
          <zem-link class="mt-3" @click="$router.replace('/login')"> Авторизация</zem-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZemInput from '../../components/ui/ZemInput.vue'
import ZemButton from '../../components/ui/ZemButton.vue'
import ZemLink from '../../components/ui/ZemLink.vue'
import User from '@/models/user'
import AuthService from '@/services/auth.service'
import {errorHandler} from '@/assets/scripts/scripts'

export default {
  name: 'Register',
  components: {
    ZemInput,
    ZemButton,
    ZemLink,
  },
  data() {
    return {
      user: new User('', '', ''),
      submitted: false,
      successful: false,
      message: '',
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.replace('/partner-orders')
    }
  },

  methods: {
    handleRegister() {
      this.message = ''
      this.submitted = true
      let isValid = true
      // this.$validator.validate().then(isValid => {
      if (isValid) {
        this.$store.dispatch('auth/register', this.user).then(
          data => {
            this.message = data.message
            this.successful = true
            this.$router.replace('/login')
          },
          error => {
            this.message = (error.response && error.response.data) || error.message || error.toString()
            this.successful = false
          }
        )
        AuthService.register(this.user)
          .then(r => {
            this.message = r.message
            this.$router.replace('/login')
            this.$store.commit('auth/registerSuccess')
          })
          .catch(error => {
            errorHandler(error)
            this.$store.commit('auth/registerFailure')
          })
      }
      // });
    },
  },
}
</script>

<style lang="scss" scoped>
.register {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  height: 70%;

  &__container {
    width: 240px;
    padding: 25px;
    background: #ffffff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }

  &__image {
    margin-bottom: 26px;
    height: 30px;
  }

  &__image-block {
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    color: $color-mine-shaft;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
  }

  &__inputs-block {
    width: 100%;

    &__buttons {
      margin-top: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .zem-input {
      margin-bottom: 16px;
    }
  }
}
</style>
